body {
  margin: 0;
  font-family: 'Nunito', sans-serif !important;
  background-color: #FBFAFB !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-open {
  filter: blur(5px); /* Adjust the blur intensity as needed */
}

.font-tarminal{
  font-family: 'Fira Code', monospace;
}